<template>
  <div class="defi-borrow-page">
    <v-row justify="center" class="my-15">
      <v-col cols="11" sm="9" md="6">
        <v-card light class="defi-borrow-card card-wrap d-flex flex-column justify-center align-center">
          <img src="@/assets/img/icon-borrow.png" width="60px" class="mb-5">
          <h2 class="primary--text mb-5">{{ $t('toBorrow') }}</h2>
          <h3 class="primary--text mb-3">{{ $t('borrowRule') }}</h3>
          <ol class="mb-5">
            <li v-for="(text, i) in $t('borrowRuleText')" :key="i" style="white-space: pre-wrap;" v-text="text"></li>
          </ol>

          <btn class="mb-5" :buttonText="'myLoans'" color="primary" isOutlined :isCenter="true" :width="270" @clickBtn="clickBtn('loans')"></btn>
          <btn class="mb-5" :buttonText="'createLoan'" color="primary" :isCenter="true" :width="270" @clickBtn="clickBtn('create')"></btn>
          <div class="can-click" @click="$router.push({name: 'Home'})">{{ $t('backToIndex') }}</div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import btn from '@/components/btn.vue'
import Defi from '@/plugins/defi.js'
export default {
  name: "Defi-borrow",
  data (){
    return {
      defiContract: null,
      isMember: false,
      rules:[
        '貸款人欲貸款前須先將抵押品存放於區塊鏈錢包中，爾後填寫貸款單提出貸款申請。',
        '成功提出貸款申請後抵押品會被轉入智能合約保管，並於「我的貸款」及 「投資清單」中看到該貸款合約，爾後等待媒合，媒合成功前貸款人可隨時修改貸款內容。',
        '媒合成功後，貸款人的區塊鏈錢包會收到扣除利息後之貸款金額，並於「我的貸款」中看到該貸款合約。',
        '貸款人於該貸款合約時間到期前可隨時還款，成功還款後智能合約會將抵押品自動歸還於貸款人的區塊鏈錢包。',
        '貸款人若於該貸款合約時間到期時未還款，則抵押品會被智能合約沒收，其所有權將歸該貸款合約之投資人所有。',
        '上述操作所產生之ETH礦工費須由貸款人自行負擔。',
      ]
    }
  },
  components:{
    btn,
  },
  methods:{
    async clickBtn(link){
      if (this.$store.state.account){
        // defi isMember
        if (this.isMember || link === 'create'){
          this.$router.push(`/${this.$route.params.lang}/borrow/${link}`)
        }else{
          this.$router.push({name: 'Defi-registry', params: {from: 'borrow'}})
        }
      }else{
        this.$toasted.error(this.$t('loginFirst'))
      }
    }
  },
  async mounted(){
    // defi contract
    this.defiContract = await new Defi()
    this.isMember = await this.defiContract.isMember(this.$store.state.account)
  }
}
</script>

<style lang="scss" scoped>
.defi-borrow-page{
  
}
</style>